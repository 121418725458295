/* eslint-disable react-refresh/only-export-components */
import { notification, Tooltip } from 'antd';
import { RuleObject } from 'antd/es/form';
import { UploadFile } from 'antd/lib/upload';
import dayjs from 'dayjs';
import moment, { Moment } from 'moment';

import { UserResponse } from '@/types/auth';
import CustomAxiosError from '@/types/error';

import { ROLES_LIST } from '../constants';

export function convertNumberToCommaSeperated(number: number) {
  if (Math.abs(number) >= 1e9) {
    return (number / 1e9).toFixed(2).toString() + ' B';
  } else if (Math.abs(number) >= 1e6) {
    return (number / 1e6).toFixed(2).toString() + ' M';
  } else {
    return number
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}

export function getMomentDateDiff(start: Moment, end: Moment) {
  return end.diff(start, 'days') + 1;
}

export function camelToSnakeCase(str: string) {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export const camelCaseToNormalCase = (camelCase: string) => {
  return camelCase
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
};

export function camelToTitleCase(str: string) {
  const result = str.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const capitalizeFirstLetter = (sentence: string) => {
  if (sentence) {
    const arr = sentence.split(' ');

    arr.forEach((_word, i) => {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
    });

    return arr.join(' ');
  }
};

export function getNameInitials(name: string | 'Ratio Tech') {
  const upperCaseName = name.toUpperCase();
  const nameArray = upperCaseName.split(' ');
  if (nameArray) {
    return nameArray.map((item) => item[0]).join('');
  }
  return '';
}

export function TooltipOnTableTitles(title: string) {
  return (
    <Tooltip title={title}>
      <span>{getNameInitials(title)}</span>
    </Tooltip>
  );
}

export function bytesToSize(bytes?: number) {
  if (bytes) {
    const decimals = 2;
    const kbToBytes = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      'Bytes',
      'KiB',
      'MiB',
      'GiB',
      'TiB',
      'PiB',
      'EiB',
      'ZiB',
      'YiB',
    ];
    const index = Math.floor(Math.log(bytes) / Math.log(kbToBytes));
    return `${parseFloat((bytes / kbToBytes ** index).toFixed(dm))} ${
      sizes[index]
    }`;
  }
}

export const validateFileType = (
  { type }: UploadFile,
  allowedTypes?: string[]
) => {
  if (!allowedTypes) {
    return true;
  }
  if (type) {
    return allowedTypes.includes(type);
  }
};

export function getRoleID(value: string) {
  const role = ROLES_LIST.find((item) => item.value === value);
  if (role) {
    return Number(role.id);
  } else {
    return 0;
  }
}

export function randomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function blockedUsers(roles: Array<string>, userRole?: number) {
  //TODO CHECK THIS
  if (userRole && roles) {
    if (roles.includes(String(userRole))) {
      return false;
    } else return true;
  } else return true;
}

export function isValidOrganizationName(val: string): {
  valid: boolean;
  error?: string;
} {
  if (val.length < 3) {
    return {
      valid: false,
      error: 'Organization name must be at least 3 characters long.',
    };
  }

  if (/[^a-zA-Z0-9\s]/.test(val)) {
    return {
      valid: false,
      error: 'Organization name must not contain special characters.',
    };
  }

  return { valid: true };
}

export function isValidAssetName(val: string): {
  valid: boolean;
  error?: string;
} {
  // Minimum 3 karakter kontrolü
  if (val.length < 3) {
    return {
      valid: false,
      error: 'Asset name must be at least 3 characters long.',
    };
  }

  // Boşluk dışında istediği karakterleri kullanabilme kontrolü
  // \s ifadesi boşluk karakterlerini temsil eder
  if (!/^[^\s]{3,}$/.test(val)) {
    return {
      valid: false,
      error: 'Asset name can only contain non-space characters.',
    };
  }

  // Geçerli isim
  return { valid: true };
}
export function checkIsMembershipStatusActive(user: UserResponse): boolean {
  // STANDARD USER CONTROL
  if (
    user &&
    user.organization &&
    user.organization.subscription &&
    user.organization.subscription.plan.name === 'demo' &&
    dayjs(user.organization.subscription.periodEndDate).diff(
      Date.now(),
      'days'
    ) <= 0
  ) {
    return true;
  } else {
    return false;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

export function isObjectEmpty(obj: AnyObject): boolean {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value === null || value === undefined) {
        return false;
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        // Eğer değer bir obje ise, recursive olarak kontrol et
        if (!isObjectEmpty(value)) {
          return false;
        }
      }

      if (Array.isArray(value)) {
        // Eğer değer bir dizi ise, her elemanı kontrol et
        for (const item of value) {
          if (typeof item === 'object' && !Array.isArray(item)) {
            // Eğer eleman bir obje ise, recursive olarak kontrol et
            if (!isObjectEmpty(item)) {
              return false;
            }
          } else if (item === null || item === undefined) {
            // Eğer eleman null veya undefined ise, false döndür
            return false;
          }
        }
      }
    }
  }
  return true;
}

export function containsNullOrUndefined(obj: AnyObject): boolean {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];

      if (value === null || value === undefined) {
        return true;
      }

      if (typeof value === 'object' && !Array.isArray(value)) {
        // Eğer değer bir obje ise, recursive olarak kontrol et
        if (containsNullOrUndefined(value)) {
          return true;
        }
      }

      if (Array.isArray(value)) {
        // Eğer değer bir dizi ise, her elemanı kontrol et
        for (const item of value) {
          if (typeof item === 'object' && !Array.isArray(item)) {
            // Eğer eleman bir obje ise, recursive olarak kontrol et
            if (containsNullOrUndefined(item)) {
              return true;
            }
          } else if (item === null || item === undefined) {
            // Eğer eleman null veya undefined ise, true döndür
            return true;
          }
        }
      }
    }
  }
  return false;
}

export const validateStrongPassword = (_: RuleObject, value: string) => {
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;"'<>,.?~\\/-]).+$/;

  if (strongPasswordRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error(
      'Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.'
    )
  );
};

export const formatDateTime = (date: string) => {
  return dayjs(date).format('DD MMM YYYY');
};

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

// Function to validate email
export const validateEmail = (_: RuleObject, value: string) => {
  if (!value) {
    return Promise.resolve(); // Boş değerler geçerli kabul edilir
  }
  if (emailRegex.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Please enter a valid email address.'));
};

export const insertLineBreak = (text: string, number: number) => {
  const words = text.split(' ');
  if (words.length <= number) return text;
  return (
    <>
      {words.slice(0, number).join(' ')}
      <br />
      {words.slice(number).join(' ')}
    </>
  );
};

//TODO remove dollars here (this is general function)
export const formatDollars = (value: number): string => {
  if (value >= 1000000 || value <= -1000000) {
    const valueInMillion: number = value / 1000000;
    return `${valueInMillion.toFixed(2)}M $`;
  } else if (value >= 1000 || value <= -1000) {
    const valueInThousand: number = value / 1000;
    return `${valueInThousand.toFixed(2)}K $`;
  } else {
    return `${value.toFixed(2)} $`;
  }
};

export const converToMillion = (value: number): string => {
  const absValue = Math.abs(value);

  if (absValue <= 10000 && absValue > 0) {
    const valueInThousands: number = value / 1000;
    return `${valueInThousands.toFixed(2)}K`;
  }
  if (absValue === 0) {
    return '0.00';
  }

  const valueInMillion: number = value / 1000000;
  return `${valueInMillion.toFixed(2)}M`;
};

export const convertToMegaWatt = (value: number): string => {
  const valueInMegaWatt: number = value / 1000;

  const formattedValue: string = `${valueInMegaWatt.toFixed(2)}`;

  return formattedValue;
};

export const getYears = (startDate: string, endDate: string) => {
  const years: number[] = [];
  const startYear = new Date(startDate).getFullYear();
  const endYear = new Date(endDate).getFullYear();
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }

  return years;
};

export const formatNumberWithCommas = (value: number) => {
  const stringValue = value?.toString().split('').reverse().join('');

  const formattedValue = stringValue
    ?.replace(/(\d{3})(?=\d)/g, '$1,')
    .split('')
    .reverse()
    .join('');

  return formattedValue;
};

export const parseCommaSeparatedNumber = (formattedValue: string) => {
  // Virgüllerin kaldırılması ve sayısal değere dönüştürülmesi
  const numberValue = parseFloat(formattedValue.replace(/,/g, ''));

  return numberValue;
};

export const formatDateToISO = (date: Date): string => {
  return date.toISOString();
};

export const getEndOfMonthDate = (date: Date): string => {
  const endOfMonth = new Date(
    Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
  );
  return endOfMonth.toISOString();
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Aylar 0-11 arası olduğu için 1 ekliyoruz
  const year = date.getUTCFullYear();

  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const infinitySymbol = (x: number | undefined | null) => {
  if (x) {
    if (x == Infinity || x == 32767) {
      return '∞';
    } else {
      return x;
    }
  } else {
    return 0;
  }
};

export const formatValue = (value?: number) => {
  if (value === undefined) return '';

  if (value >= 1000000 || value <= -1000000) {
    return `${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000 || value <= -1000) {
    return `${(value / 1000).toFixed(1)}k`;
  } else {
    return `${value.toFixed(1)}`;
  }
};

export function formatNumericPositiveOnly(
  value: number | null
): number | null | undefined | boolean {
  // Gelen değeri stringe dönüştür
  const stringValue = String(value);
  const isNumeric = /^-?\d*(\.\d*)?$/.test(stringValue);

  // Değer null değilse, sayısal ve numeric ise değeri döndür
  if (value !== null && !isNaN(Number(value)) && isNumeric) {
    return value;
  }

  // Geçici durumları kontrol et
  if (stringValue === '' || stringValue === '-') {
    return value;
  }

  return false;
}

export const normalizePositiveNumericValuesInput = (input: string): string => {
  let cleanedInput = input.replace(/[^\d.,]/g, '');
  cleanedInput = cleanedInput.replace(',', '.');
  cleanedInput = cleanedInput.replace(/^[.,]/, '');
  const decimalParts = cleanedInput.split('.');
  const integerPart = decimalParts[0].replace(/[^\d]/g, '');
  const decimalPart =
    decimalParts.length > 1 ? `.${decimalParts[1].replace(/[^\d]/g, '')}` : '';

  return integerPart + decimalPart;
};

export const normalizePositiveIntegerValuesInput = (input: string): string => {
  let cleanedInput = input.replace(/[^\d]/g, ''); // Sadece rakamları kabul eder

  const negativeSign = cleanedInput.startsWith('-') ? '-' : '';
  cleanedInput = cleanedInput.replace('-', ''); // Eksi işareti kaldırır

  const decimalIndex = cleanedInput.indexOf('.');
  if (decimalIndex !== -1) {
    cleanedInput = cleanedInput.substring(0, decimalIndex);
  }

  if (negativeSign === '-') {
    return '0';
  }

  return negativeSign + cleanedInput;
};

export const getYearsInRangeOptions = (
  startDate: string,
  endDate: string
): number[] => {
  const startYear = new Date(startDate).getUTCFullYear();
  const endYear = new Date(endDate).getUTCFullYear();
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

export const dateStringToISO8601 = (date: string): string => {
  return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');
};

export const formatDateXAxis = (date: string, unit: string) => {
  const utcDate = moment.utc(date); // Ensure the date is treated as UTC
  switch (unit) {
    case 'year':
      return utcDate.format('YYYY');
    case 'month':
      return utcDate.format('YYYY-MM');
    case 'week':
      return `Week ${utcDate.week()} ${utcDate.format('YYYY')}`;
    case 'day':
      return utcDate.format('YYYY-MM-DD');
    case 'hour':
      return utcDate.format('YYYY-MM-DD HH:00');
    default:
      return utcDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
  }
};

export const tickFormatter = (value: number) => {
  const absValue = Math.abs(value);
  if (absValue >= 1e6) {
    return `$${(value / 1e6).toFixed(1)}M`;
  } else if (absValue >= 1e3) {
    return `$${(value / 1e3).toFixed(0)}K`;
  } else {
    return `$${value}`;
  }
};
export const tickFormatterWithoutDollars = (value: number) => {
  const absValue = Math.abs(value);
  if (absValue >= 1e6) {
    return `${(value / 1e6).toFixed(1)}M`;
  } else if (absValue >= 1e3) {
    return `${(value / 1e3).toFixed(0)}K`;
  } else {
    return `${value}`;
  }
};

export const getTickStep = (value: number) => {
  if (value >= 1e6) return 1e6;
  if (value >= 1e3) return 1e3;
  return 1;
};

export const displayNotification = (
  type: 'success' | 'error' | 'info',
  message: string
) => {
  notification[type]({ message });
};

export const handleError = (
  error: unknown,
  message: string = 'An error occurred.'
) => {
  const axiosError = error as CustomAxiosError;
  displayNotification(
    'error',
    axiosError.response?.data.userMessage || message
  );
};

export const formattedPhoneNumber = (phone: string) => {
  const cleaned = ('' + phone).replace(/\D/g, '');

  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
      6,
      10
    )}`;
  } else if (cleaned.length === 7) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 4)} ${cleaned.slice(
      4,
      7
    )}`;
  } else if (cleaned.length > 3) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  } else {
    return cleaned;
  }
};

export const fullNameRegExpPattern = () => /^[a-zA-ZçÇğĞşŞöÖüÜıİ\s.]+$/i;

export function createQueryString(params: Record<string, unknown>): string {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    // `name` anahtarı için `all` değeri geçerli
    if (key === 'name') {
      queryParams.append(key, value as string);
    }
    // Diğer anahtarlar için `all` değeri geçerli değil
    else if (value !== undefined && value !== null && value !== 'all') {
      // `value` türünü kontrol edip uygun dönüşüm yapıyoruz
      queryParams.append(
        key,
        value === 'active'
          ? 'true'
          : value === 'inactive'
            ? 'false'
            : String(value)
      );
    }
  });

  return queryParams.toString();
}

export function hasNonEmptyValues(obj: Record<string, unknown>) {
  return Object.values(obj).some(
    (value) => value !== '' && value !== null && value !== undefined
  );
}

export const formatInputNumber = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const removeCommas = (value: string) => value.replace(/,/g, '');

export const getParticipationStatusLabel = (status: number) => {
  switch (status) {
    case 0:
      return 'Do not participate';
    // case 1:
    //   return 'Participate';
    case 2:
      return 'Depends on the model';
    default:
      return 'Do not participate';
  }
};

export const formatNumberWithSuffix = (value: number) => {
  if (value >= 1e9) {
    return `${(value / 1e9).toFixed(2)}B`;
  }
  if (value >= 1e6) {
    return `${(value / 1e6).toFixed(2)}M`;
  }
  if (value >= 1e3) {
    return `${(value / 1e3).toFixed(2)}K`;
  }
  return value.toString();
};
