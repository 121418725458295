import { FC } from 'react';

import { Col, Form, Input, Row, Typography } from 'antd';
import { useTheme } from 'styled-components';

import { StorageSystemForm } from '@/types/storageSystem';
import { normalizePositiveNumericValuesInput } from '@/utils';

type Props = {
  disabled?: boolean;
};

const GridAction: FC<Props> = ({ disabled = false }) => {
  const theme = useTheme();

  return (
    <Form.Item
      wrapperCol={{ span: 24 }}
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      className="p-16"
    >
      <Row>
        <Col span={12}>
          <Form.Item<StorageSystemForm>
            className="pr-8"
            name="gridPowerConstraintOutflow"
            label="Grid Power Outflow Constraint"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Grid Power Outflow Constraint is required',
              },
            ]}
          >
            <Input
              disabled={disabled}
              addonAfter="MW"
              placeholder="Grid Power Outflow Constraint"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<StorageSystemForm>
            //TODO Reusable FormTooltips
            tooltip={{
              title: (
                <div>
                  <>
                    <Typography.Text className="fs-14-semi-bold">
                      Energy Distribution Fee of Sold Energy
                    </Typography.Text>
                    <div className="horizontal-line mt-4"></div>
                  </>
                  <Typography.Text className="fs-14-regular smoke mt-4">
                    The cost charged for injecting electricity into the grid,
                    measured per megawatt-hour
                  </Typography.Text>
                </div>
              ),
              overlayInnerStyle: {
                padding: theme.spacing.spacing12 + 'px',
                color: 'inherit',
              },
              color: theme.colors.light,
            }}
            className="pl-8"
            name="distributionFeePerMwh"
            label="Energy Distribution Fee of Sold Energy"
            normalize={normalizePositiveNumericValuesInput}
          >
            <Input
              disabled={disabled}
              addonAfter="$/MWh"
              placeholder="Energy Distribution Fee of Sold Energy"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item<StorageSystemForm>
            className="pr-8"
            name="gridPowerConstraintInflow"
            label="Grid Power Inflow Constraint"
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Grid Power Inflow Constraint is required',
              },
            ]}
          >
            <Input
              disabled={disabled}
              addonAfter="MW"
              placeholder="Grid Power Inflow Constraint"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item<StorageSystemForm>
            className="pl-8"
            name="withdrawalFeePerMwh"
            label="Energy Distribution Fee of Purchased Energy"
            normalize={normalizePositiveNumericValuesInput}
            //TODO Reusable FormTooltips
            tooltip={{
              title: (
                <div>
                  <>
                    <Typography.Text className="fs-14-semi-bold">
                      Energy Distribution Fee of Purchased Energy
                    </Typography.Text>
                    <div className="horizontal-line mt-4"></div>
                  </>
                  <Typography.Text className="fs-14-regular smoke mt-4">
                    The cost charged for withdrawing electricity from the grid,
                    measured per megawatt-hour
                  </Typography.Text>
                </div>
              ),
              overlayInnerStyle: {
                padding: theme.spacing.spacing12 + 'px',
                color: 'inherit',
              },
              color: theme.colors.light,
            }}
          >
            <Input
              disabled={disabled}
              addonAfter="$/MWh"
              placeholder="Energy Distribution Fee of Purchased Energy"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default GridAction;
