import { Form } from 'antd';
import styled from 'styled-components';

export default styled(Form.Item)`
  .ant-upload-wrapper {
    width: 100%;
  }

  .ant-form-item-control {
    height: 150px;
  }

  .ant-upload-list-item-progress {
    /* bottom:24px !important; */
    margin-bottom: 12px !important;
    /* margin-left:42px !important; */
    width: calc(100% - 36px) !important;
    padding-inline-start: 93px !important;
  }

  .ant-progress-bg {
    height: 8px !important;
  }

  .ant-upload-select {
    cursor: pointer;
    width: 100% !important;
    height: 128px;
    border: 2px dashed ${({ theme }) => theme.colors.grayLightAccent};
    border-radius: ${({ theme }) => theme.main.borderRadius}px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .ant-upload-list-item-container {
    height: 128px;

    .ant-upload-list-item {
      height: 128px !important;
      padding: 24px 17px !important;
    }

    a {
      height: 84px !important;
      width: 84px !important;
    }

    .ant-upload-list-item-actions {
      .ant-btn {
        padding: 12px !important;
      }
      span {
        font-size: 18px;
      }
    }
  }

  .ant-upload-list-item-thumbnail {
    height: 84px !important;
    width: 84px !important;
    span {
      height: 100%;
      height: 100%;

      svg {
        font-size: 48px;
      }
    }
  }

  .ant-form-item-control-input {
    min-height: auto !important;
  }
  .upload-error > div > div {
    height: 60px !important;
  }
  .ant-upload-list-item-action:hover .ant-btn-icon span svg {
    fill: #00000073 !important;
  }

  .ant-upload-hidden {
    display: none;
  }
`;
