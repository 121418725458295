import { FC, useState, useEffect } from 'react';

import {
  Col,
  FormInstance,
  InputNumber,
  Row,
  Typography,
  Tabs,
  TabsProps,
  Card,
} from 'antd';

import { StorageSystemForm } from '@/types/storageSystem';

type Props = {
  disabled?: boolean;
  form: FormInstance<StorageSystemForm>;
};

const AncillaryServices: FC<Props> = ({ disabled = false, form }) => {
  const [pfcActiveTab, setPfcActiveTab] = useState<string>('blockHour');
  const [sfcActiveTab, setSfcActiveTab] = useState<string>('blockHour');

  // Yerel state’ler: blok ve saat bazında kapasite değerleri
  const [pfcBlockCapacities, setPfcBlockCapacities] = useState<number[]>(
    form.getFieldValue('pfcBlockCapacities') || Array(6).fill(0)
  );
  const [pfcHourCapacities, setPfcHourCapacities] = useState<number[]>(
    form.getFieldValue('pfcHourCapacities') || Array(24).fill(0)
  );
  const [sfcBlockCapacities, setSfcBlockCapacities] = useState<number[]>(
    form.getFieldValue('sfcBlockCapacities') || Array(6).fill(0)
  );
  const [sfcHourCapacities, setSfcHourCapacities] = useState<number[]>(
    form.getFieldValue('sfcHourCapacities') || Array(24).fill(0)
  );

  // İlk yüklemenin yapıldığını takip eden flag
  const [initialDataLoaded, setInitialDataLoaded] = useState<boolean>(false);

  const blockToHourMap = [
    [0, 1, 2, 3], // 00:00 - 03:59
    [4, 5, 6], // 04:00 - 06:59
    [7, 8, 9, 10], // 07:00 - 10:59
    [11, 12, 13, 14, 15], // 11:00 - 15:59
    [16, 17, 18, 19], // 16:00 - 19:59
    [20, 21, 22, 23], // 20:00 - 23:59
  ];
  const timeSpans = [
    '00:00 - 03:59',
    '04:00 - 06:59',
    '07:00 - 10:59',
    '11:00 - 15:59',
    '16:00 - 19:59',
    '20:00 - 23:59',
  ];

  // Belirli bir saatin hangi blokta olduğunu bulur
  const getBlockForHour = (hour: number) => {
    for (let i = 0; i < blockToHourMap.length; i++) {
      if (blockToHourMap[i].includes(hour)) return i;
    }
    return -1;
  };

  // Component mount olduktan sonra formdaki değerleri okuyup yerel state’e aktarıyoruz.
  useEffect(() => {
    const currentPfcCapacities =
      form.getFieldValue('pfcCapacities') || Array(24).fill(0);
    const currentSfcCapacities =
      form.getFieldValue('sfcCapacities') || Array(24).fill(0);
    const currentPfcOfferTypes =
      form.getFieldValue('pfcOfferTypes') || Array(24).fill(0);
    const currentSfcOfferTypes =
      form.getFieldValue('sfcOfferTypes') || Array(24).fill(0);

    // Eğer formda daha önce set edilmiş (sıfırdan farklı) değer varsa,
    // yerel state’i bu değerlere göre oluşturuyoruz.
    const hasInitialData =
      currentPfcCapacities.some((val: number) => val !== 0) ||
      currentSfcCapacities.some((val: number) => val !== 0) ||
      currentPfcOfferTypes.some((val: number) => val !== 0) ||
      currentSfcOfferTypes.some((val: number) => val !== 0);

    if (!initialDataLoaded && hasInitialData) {
      // PFC için blok ve saat değerlerini hesaplıyoruz
      const pfcBlocks = Array(6).fill(0);
      const pfcHours = Array(24).fill(0);
      blockToHourMap.forEach((hours, blockIndex) => {
        const blockValue = currentPfcCapacities[hours[0]];
        const isBlockOffer = hours.every(
          (h) =>
            currentPfcCapacities[h] === blockValue &&
            currentPfcOfferTypes[h] === 1
        );
        if (isBlockOffer && blockValue > 0) {
          pfcBlocks[blockIndex] = blockValue;
        } else {
          hours.forEach((hour) => {
            if (
              currentPfcOfferTypes[hour] === 0 &&
              currentPfcCapacities[hour] > 0
            ) {
              pfcHours[hour] = currentPfcCapacities[hour];
            }
          });
        }
      });
      setPfcBlockCapacities(pfcBlocks);
      setPfcHourCapacities(pfcHours);

      // SFC için blok ve saat değerlerini hesaplıyoruz
      const sfcBlocks = Array(6).fill(0);
      const sfcHours = Array(24).fill(0);
      blockToHourMap.forEach((hours, blockIndex) => {
        const blockValue = currentSfcCapacities[hours[0]];
        const isBlockOffer = hours.every(
          (h) =>
            currentSfcCapacities[h] === blockValue &&
            currentSfcOfferTypes[h] === 1
        );
        if (isBlockOffer && blockValue > 0) {
          sfcBlocks[blockIndex] = blockValue;
        } else {
          hours.forEach((hour) => {
            if (
              currentSfcOfferTypes[hour] === 0 &&
              currentSfcCapacities[hour] > 0
            ) {
              sfcHours[hour] = currentSfcCapacities[hour];
            }
          });
        }
      });
      setSfcBlockCapacities(sfcBlocks);
      setSfcHourCapacities(sfcHours);
      setInitialDataLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, initialDataLoaded]);

  // Yerel state’lerden form verilerini hesaplayan fonksiyon
  const calculateCapacitiesAndOfferTypes = (
    blockCapacities: number[],
    hourCapacities: number[]
  ) => {
    const capacities = Array(24).fill(0);
    const offerTypes = Array(24).fill(0);

    blockToHourMap.forEach((hours, blockIndex) => {
      if (blockCapacities[blockIndex] > 0) {
        // Blok teklif girilmişse
        hours.forEach((hour) => {
          capacities[hour] = blockCapacities[blockIndex];
          offerTypes[hour] = 1;
        });
      } else {
        // Blok teklif yoksa, saatlik teklif kullanılır
        hours.forEach((hour) => {
          capacities[hour] = hourCapacities[hour];
          offerTypes[hour] = 0;
        });
      }
    });
    return { capacities, offerTypes };
  };

  // Yerel state değiştiğinde form verilerini güncelliyoruz.
  useEffect(() => {
    const { capacities: pfcCapacities, offerTypes: pfcOfferTypes } =
      calculateCapacitiesAndOfferTypes(pfcBlockCapacities, pfcHourCapacities);
    const { capacities: sfcCapacities, offerTypes: sfcOfferTypes } =
      calculateCapacitiesAndOfferTypes(sfcBlockCapacities, sfcHourCapacities);

    form.setFieldsValue({
      pfcCapacities,
      pfcOfferTypes,
      sfcCapacities,
      sfcOfferTypes,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pfcBlockCapacities,
    pfcHourCapacities,
    sfcBlockCapacities,
    sfcHourCapacities,
    form,
  ]);

  // Blok teklif girildiğinde, ilgili saat tekliflerini sıfırlıyoruz.
  useEffect(() => {
    if (initialDataLoaded) {
      const newPfcHourCapacities = [...pfcHourCapacities];
      blockToHourMap.forEach((hours, blockIndex) => {
        if (pfcBlockCapacities[blockIndex] > 0) {
          hours.forEach((hour) => {
            newPfcHourCapacities[hour] = 0;
          });
        }
      });
      setPfcHourCapacities(newPfcHourCapacities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pfcBlockCapacities, initialDataLoaded]);

  useEffect(() => {
    if (initialDataLoaded) {
      const newSfcHourCapacities = [...sfcHourCapacities];
      blockToHourMap.forEach((hours, blockIndex) => {
        if (sfcBlockCapacities[blockIndex] > 0) {
          hours.forEach((hour) => {
            newSfcHourCapacities[hour] = 0;
          });
        }
      });
      setSfcHourCapacities(newSfcHourCapacities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sfcBlockCapacities, initialDataLoaded]);

  // Her saat için kullanılabilir kapasite hesaplamaları
  const getAvailableForPFC = (hour: number) => {
    const sfcCaps = form.getFieldValue('sfcCapacities') || Array(24).fill(0);
    return 100 - sfcCaps[hour];
  };

  const getAvailableForSFC = (hour: number) => {
    const pfcCaps = form.getFieldValue('pfcCapacities') || Array(24).fill(0);
    return 100 - pfcCaps[hour];
  };

  // Input değişiklikleri için handlerlar
  const handlePfcBlockChange = (index: number, val: number | null) => {
    const maxAllowed = Math.min(
      ...blockToHourMap[index].map(getAvailableForPFC)
    );
    const validatedValue = val !== null ? Math.min(val, maxAllowed) : 0;
    const newBlocks = [...pfcBlockCapacities];
    newBlocks[index] = validatedValue;
    setPfcBlockCapacities(newBlocks);
  };

  const handlePfcHourChange = (hour: number, val: number | null) => {
    const maxAllowed = getAvailableForPFC(hour);
    const validatedValue = val !== null ? Math.min(val, maxAllowed) : 0;
    const newHours = [...pfcHourCapacities];
    newHours[hour] = validatedValue;
    setPfcHourCapacities(newHours);
  };

  const handleSfcBlockChange = (index: number, val: number | null) => {
    const maxAllowed = Math.min(
      ...blockToHourMap[index].map(getAvailableForSFC)
    );
    const validatedValue = val !== null ? Math.min(val, maxAllowed) : 0;
    const newBlocks = [...sfcBlockCapacities];
    newBlocks[index] = validatedValue;
    setSfcBlockCapacities(newBlocks);
  };

  const handleSfcHourChange = (hour: number, val: number | null) => {
    const maxAllowed = getAvailableForSFC(hour);
    const validatedValue = val !== null ? Math.min(val, maxAllowed) : 0;
    const newHours = [...sfcHourCapacities];
    newHours[hour] = validatedValue;
    setSfcHourCapacities(newHours);
  };

  // Input’ların devre dışı bırakılma koşulları
  const isPfcBlockDisabled = (blockIndex: number) => {
    if (disabled) return true;
    const hours = blockToHourMap[blockIndex];
    const hasHourOffer = hours.some((hour) => pfcHourCapacities[hour] > 0);
    const sfcFull = hours.some((hour) => getAvailableForPFC(hour) === 0);
    return hasHourOffer || sfcFull;
  };

  const isPfcHourDisabled = (hour: number) => {
    if (disabled) return true;
    const blockIndex = getBlockForHour(hour);
    const hasBlockOffer =
      blockIndex !== -1 && pfcBlockCapacities[blockIndex] > 0;
    const sfcFull = getAvailableForPFC(hour) === 0;
    return hasBlockOffer || sfcFull;
  };

  const isSfcBlockDisabled = (blockIndex: number) => {
    if (disabled) return true;
    const hours = blockToHourMap[blockIndex];
    const hasHourOffer = hours.some((hour) => sfcHourCapacities[hour] > 0);
    const pfcFull = hours.some((hour) => getAvailableForSFC(hour) === 0);
    return hasHourOffer || pfcFull;
  };

  const isSfcHourDisabled = (hour: number) => {
    if (disabled) return true;
    const blockIndex = getBlockForHour(hour);
    const hasBlockOffer =
      blockIndex !== -1 && sfcBlockCapacities[blockIndex] > 0;
    const pfcFull = getAvailableForSFC(hour) === 0;
    return hasBlockOffer || pfcFull;
  };

  // PFC Block Hour Offer Render
  const renderPfcBlockHourContent = () => (
    <>
      <Row className="mb-24">
        <Typography.Text type="secondary">
          Enter the percentage of battery capacity allocated during block hours.
          A block hour offer and an hour offer cannot be submitted for the same
          hour for PFC.
        </Typography.Text>
      </Row>
      <Row gutter={40} className="mb-24" align="middle" justify="center">
        <Col span={12}>
          <Typography.Text>Time Gaps</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>Capacity Reserved for PFC (%)</Typography.Text>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={12}>
          {timeSpans.map((item) => (
            <Row key={item} className="mb-34">
              <Typography.Text>{item}</Typography.Text>
            </Row>
          ))}
        </Col>
        <Col span={12}>
          {pfcBlockCapacities.map((value, index) => (
            <Row key={index} gutter={10} wrap={false} className="mb-24">
              <Col flex={1}>
                <InputNumber
                  value={value}
                  onChange={(val) => handlePfcBlockChange(index, val)}
                  max={Math.min(
                    ...blockToHourMap[index].map(getAvailableForPFC)
                  )}
                  min={0}
                  step={1}
                  precision={1}
                  formatter={(value) =>
                    value !== undefined
                      ? `${Number(value).toFixed(1).replace(/\.0$/, '')}`
                      : ''
                  }
                  parser={(value) => (value ? parseFloat(value) : 0)}
                  className="w-100"
                  addonAfter="%"
                  disabled={isPfcBlockDisabled(index)}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );

  const renderPfcHourOfferContent = () => (
    <>
      <Row className="mb-24">
        <Typography.Text type="secondary">
          Enter the percentage of battery capacity allocated for the selected
          hours. A block hour offer and an hour offer cannot be submitted for
          the same hour for PFC.
        </Typography.Text>
      </Row>
      <Row gutter={40} className="mb-24" align="middle" justify="center">
        <Col span={12}>
          <Typography.Text>Time Gaps</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>Capacity Reserved for PFC (%)</Typography.Text>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={12}>
          {Array.from({ length: 24 }, (_, i) => (
            <Row key={i} className="mb-34">
              <Typography.Text>{`${String(i).padStart(2, '0')}:00 - ${String(
                i
              ).padStart(2, '0')}:59`}</Typography.Text>
            </Row>
          ))}
        </Col>
        <Col span={12}>
          {pfcHourCapacities.map((value, hour) => (
            <Row key={hour} gutter={10} wrap={false} className="mb-24">
              <Col flex={1}>
                <InputNumber
                  value={value}
                  onChange={(val) => handlePfcHourChange(hour, val)}
                  max={getAvailableForPFC(hour)}
                  min={0}
                  step={1}
                  precision={1}
                  formatter={(value) =>
                    value !== undefined
                      ? `${Number(value).toFixed(1).replace(/\.0$/, '')}`
                      : ''
                  }
                  parser={(value) => (value ? parseFloat(value) : 0)}
                  className="w-100"
                  addonAfter="%"
                  disabled={isPfcHourDisabled(hour)}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );

  const renderSfcBlockHourContent = () => (
    <>
      <Row className="mb-24">
        <Typography.Text type="secondary">
          Enter the percentage of battery capacity allocated during block hours.
          A block hour offer and an hour offer cannot be submitted for the same
          hour for SFC.
        </Typography.Text>
      </Row>
      <Row gutter={40} className="mb-24" align="middle" justify="center">
        <Col span={12}>
          <Typography.Text>Time Gaps</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>Capacity Reserved for SFC (%)</Typography.Text>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={12}>
          {timeSpans.map((item) => (
            <Row key={item} className="mb-34">
              <Typography.Text>{item}</Typography.Text>
            </Row>
          ))}
        </Col>
        <Col span={12}>
          {sfcBlockCapacities.map((value, index) => (
            <Row key={index} gutter={10} wrap={false} className="mb-24">
              <Col flex={1}>
                <InputNumber
                  value={value}
                  onChange={(val) => handleSfcBlockChange(index, val)}
                  max={Math.min(
                    ...blockToHourMap[index].map(getAvailableForSFC)
                  )}
                  min={0}
                  step={1}
                  precision={1}
                  formatter={(value) =>
                    value !== undefined
                      ? `${Number(value).toFixed(1).replace(/\.0$/, '')}`
                      : ''
                  }
                  parser={(value) => (value ? parseFloat(value) : 0)}
                  className="w-100"
                  addonAfter="%"
                  disabled={isSfcBlockDisabled(index)}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );

  const renderSfcHourOfferContent = () => (
    <>
      <Row className="mb-24">
        <Typography.Text type="secondary">
          Enter the percentage of battery capacity allocated for the selected
          hours. A block hour offer and an hour offer cannot be submitted for
          the same hour for SFC.
        </Typography.Text>
      </Row>
      <Row gutter={40} className="mb-24" align="middle" justify="center">
        <Col span={12}>
          <Typography.Text>Time Gaps</Typography.Text>
        </Col>
        <Col span={12}>
          <Typography.Text>Capacity Reserved for SFC (%)</Typography.Text>
        </Col>
      </Row>
      <Row gutter={40}>
        <Col span={12}>
          {Array.from({ length: 24 }, (_, i) => (
            <Row key={i} className="mb-34">
              <Typography.Text>{`${String(i).padStart(2, '0')}:00 - ${String(
                i
              ).padStart(2, '0')}:59`}</Typography.Text>
            </Row>
          ))}
        </Col>
        <Col span={12}>
          {sfcHourCapacities.map((value, hour) => (
            <Row key={hour} gutter={10} wrap={false} className="mb-24">
              <Col flex={1}>
                <InputNumber
                  value={value}
                  onChange={(val) => handleSfcHourChange(hour, val)}
                  max={getAvailableForSFC(hour)}
                  min={0}
                  step={1}
                  precision={1}
                  formatter={(value) =>
                    value !== undefined
                      ? `${Number(value).toFixed(1).replace(/\.0$/, '')}`
                      : ''
                  }
                  parser={(value) => (value ? parseFloat(value) : 0)}
                  className="w-100"
                  addonAfter="%"
                  disabled={isSfcHourDisabled(hour)}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );

  const pfcTabItems: TabsProps['items'] = [
    {
      key: 'blockHour',
      label: 'Block Hour Offer',
      children: renderPfcBlockHourContent(),
    },
    {
      key: 'hourOffer',
      label: 'Hourly Offer',
      children: renderPfcHourOfferContent(),
    },
  ];

  const sfcTabItems: TabsProps['items'] = [
    {
      key: 'blockHour',
      label: 'Block Hour Offer',
      children: renderSfcBlockHourContent(),
    },
    {
      key: 'hourOffer',
      label: 'Hourly Offer',
      children: renderSfcHourOfferContent(),
    },
  ];

  return (
    <>
      <Card
        title={
          <Typography.Title level={4}>
            Primary Frequency Control (PFC)
          </Typography.Title>
        }
        className="mb-24 p-24"
        bordered
      >
        <Tabs
          activeKey={pfcActiveTab}
          onChange={setPfcActiveTab}
          items={pfcTabItems}
        />
      </Card>
      <Card
        title={
          <Typography.Title level={4}>
            Secondary Frequency Control (SFC)
          </Typography.Title>
        }
        bordered
        className="p-24"
      >
        <Tabs
          activeKey={sfcActiveTab}
          onChange={setSfcActiveTab}
          items={sfcTabItems}
        />
      </Card>
    </>
  );
};

export default AncillaryServices;
