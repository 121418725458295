import { createGlobalStyle } from 'styled-components';

import type { Theme } from '.';

const spacingDirectionKeys: Record<string, string[]> = {
  '': [],
  x: ['left', 'right'],
  y: ['bottom', 'top'],
  l: ['left'],
  r: ['right'],
  b: ['bottom'],
  t: ['top'],
};
const spacingKeys = {
  margin: { key: 'm', css: 'margin' },
  padding: { key: 'p', css: 'padding' },
};

function generateSpacingCSS(theme: Theme): string {
  const spacingSizes = Object.values(theme.spacing);

  // m-auto, m-0 ... mx-auto, mx-0 ...  p-auto, p-0
  return spacingSizes
    .map((size) => {
      return Object.values(spacingKeys)
        .map((spacingKey) => {
          return Object.entries(spacingDirectionKeys)
            .map(([directionKey, directions]) => {
              if (directions.length > 0) {
                return `.${spacingKey.key}${directionKey}-${size}{
            ${directions
              .map(
                (direction) =>
                  `${spacingKey.css}-${direction}: ${size}px !important;`
              )
              .join('')}
          }`;
              }
              return `.${spacingKey.key}${directionKey}-${size}{${spacingKey.css}: ${size}px !important;}`;
            })
            .join('');
        })
        .join('');
    })
    .join('');
}

const fontWeightKeys: Record<string, number> = {
  'extra-bold': 800,
  bold: 700,
  'semi-bold': 600,
  medium: 500,
  regular: 400,
  thin: 300,
};

function generateFontSizesCSS(theme: Theme): string {
  const fontSizes = Object.entries(theme.fontSizes);
  const fontWeights = Object.entries(fontWeightKeys);
  return fontSizes
    .map(([className, size]) => {
      const classSuffix = className.replace('fontSize', '');
      const baseClass = `.fs-${classSuffix} { font-size: ${size}px !important;  line-height: ${
        size * 1.5
      }px !important; }\n`;
      const weightClasses = fontWeights
        .map(([weightKey, weightValue]) => {
          return `.fs-${classSuffix}-${weightKey} { font-size: ${size}px !important; font-weight: ${weightValue} !important; line-height: ${
            size * 1.5
          }px !important;}`;
        })
        .join('\n');
      return baseClass + weightClasses;
    })
    .join('\n');
}

//lh-12, lh-20 ....
function generateLineHeightsCSS(theme: Theme): string {
  const lineHeights = Object.entries(theme.fontSizes);
  return lineHeights
    .map(([className, height]) => {
      const classSuffix = className.replace('fontSize', '');
      return `.lh-${classSuffix} { line-height: ${height}px !important; }`;
    })
    .join('\n');
}

// is-12, is-20 ....
function generateIconSizesCSS(theme: Theme): string {
  const fontSizes = Object.entries(theme.fontSizes);
  return fontSizes
    .map(([className, size]) => {
      const classSuffix = className.replace('fontSize', '');
      const baseClass = `.is-${classSuffix} { font-size: ${size}px !important; }\n`;
      return baseClass;
    })
    .join('\n');
}

export default createGlobalStyle`

  html, body {
    margin:0;
    padding:0;

  }
  *{
    font-family: 'Lato', sans-serif;
  }

  // SPACING
  ${({ theme }) => generateSpacingCSS(theme)}

  //FONT SIZES
  ${({ theme }) => generateFontSizesCSS(theme)}

  //LINE HEIGHTS
  ${({ theme }) => generateLineHeightsCSS(theme)}

  //ICON SIZES
  ${({ theme }) => generateIconSizesCSS(theme)}

  // COLOR
  .primary {
    color: ${({ theme }) => theme.colors.primary};
  }
  .secondary {
    color: ${({ theme }) => theme.colors.blueDark};
  }

  .smoke {
    color: ${({ theme }) => theme.colors.grayPrimary} !important;
  }

  .smoke-secondary {
    color: ${({ theme }) => theme.colors.grayDark} !important;
  }

  .background {
    background: ${({ theme }) => theme.colors.blueLightest};
  }

  .shadow {
    box-shadow: ${({ theme }) => theme.main.shadow};
  }
  .shadow-hover {
    box-shadow: ${({ theme }) => theme.main.shadowHover};
  }

  .opacity-50 {
    opacity: 0.5;
  }
  .opacity-40 {
    opacity: 0.4;
  }

  .underline{
    text-decoration: underline !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .hover-scale {
    transition: transform ${({ theme }) => theme.main.defaultAnimation};

    &:hover {
      transform: scale(1.05);
    }
  }

  .hover-primary{
    :hover {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }



  .overflow-visible {
    overflow: visible;
  }

  .shaped {
    border-radius: ${({ theme }) => theme.main.borderRadius};
  }

  //HORIZONTAL LINE

  .horizontal-line {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grayLightAccent} ;
    height: 1px;
  }

  // SIZE
  .w-100 {
    width: 100%;
  }
  .w-25 {
    width: 25%;
  }
  .w-50 {
    width: 50%;
  }
  .w-40 {
    width: 40%;
  }
  .w-60 {
    width: 60%;
  }
  .w-75 {
    width: 75%;
  }
  .h-100 {
    height: 100%;
  }

  .limited-content {
    max-width: ${({ theme }) => theme.main.contentMaxWidth}px;
  }

  // DISPLAY

  .d-block {
    display: block;
  }
  .d-none {
    display: none;
  }
  .d-inline {
    display: inline;
  }
  .d-flex {
    display: flex;
  }
  .invisible {
    visibility: hidden;
  }

  //FLEX SHORTCUTS

  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }

  .justify-center {
    justify-items: center;
  }
  .justify-start {
    justify-items: flex-start;
  }
  .justify-end {
    justify-items: flex-end;
  }

  // TEXT

  .ff-secondary{
    font-family: "Montserrat" !important;
  }

  h1,h2,h3,h4,h5,h6 {
    margin-top:0px !important;
  }

  .ant-typography{
    margin-bottom: 0px !important;
  }

  .extra-bold{
    font-weight: 900;
  }

  .semi-bold {
    font-weight: 600;
  }

  .bold{
    font-weight:700 !important;
  }

  .thin {
    font-weight: 300 !important;
  }

  .regular {
    font-weight: 400 !important;
  }

  .medium {
    font-weight: 500;
  }

  .light {
    color: ${({ theme }) => theme.colors.light} !important;
  }

  .dark {
    color: ${({ theme }) => theme.colors.dark};
  }

  .dark-6 {
    color: ${({ theme }) => theme.colors.grayAccent}
  }

  .dark-transparent {
    color: ${({ theme }) => theme.colors.darkTransparent};
  }

  .dark-gray {
    color: ${({ theme }) => theme.colors.grayDarkPrimary} !important;
  }

  .text-center {
    text-align: center;
  }
  .text-r {
    text-align: right;
  }

  .truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Product Tour
  @keyframes fadeIn {
    0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  }
  .ant-tour{
    width: 440px !important;
    .ant-tour-arrow::after{
      background: linear-gradient(45deg, #D6C8D7 0%, #FFFFFF 100%);
    }
  }

  .ant-tour-inner {
    background: linear-gradient(180deg, #D6C8D7 0%, #FFFFFF 100%);
    display:flex;
    flex-direction:column;
    gap:5px;
    animation: fadeIn 400ms ease-in-out;
 
   .ant-tour-header{
     .ant-tour-title{
        font-size:16px !important;
     }
   }
   .ant-tour-footer{
    align-items:center;
    .ant-tour-buttons > button{
      padding:4px 15px;
      height:26px;
    }
    .ant-tour-buttons > .ant-tour-prev-btn{
     background:transparent;
    }
   }
  }

  .text-dark-color{
    color : ${({ theme }) => theme.colors.grayDarkPrimary} !important;
  }
  .text-light-color{
    color : ${({ theme }) => theme.colors.light} !important;
  }
  .text-primary-color{
    color : ${({ theme }) => theme.colors.primary} !important;
  }
  .text-faded-color{
    color : ${({ theme }) => theme.colors.grayAccent} !important;
  }
  .text-secondary-color{
    color : ${({ theme }) => theme.colors.blueSecondary} !important;
  }
  .text-blue-color{
    color : ${({ theme }) => theme.colors.bluePrimary} !important;
  }
  .text-number-color{
    color : ${({ theme }) => theme.colors.tagFailure} !important;
  }
  .text-gray-color{
    color : ${({ theme }) => theme.colors.grayPrimary} !important;
  }
  .text-black-color{
    color : ${({ theme }) => theme.colors.dark} !important;
  }

  //FORM
  
  
  .ant-form-item-label{
    padding-bottom: 12px !important;
  }
  .ant-form-item-required{
    color: ${({ theme }) => theme.colors.dark} !important;
  } 


  // DRAWER
  .ant-drawer-content{
    position: relative !important;
  } 

  .ant-modal-wrap{
    z-index: 9999 !important;
  }
  .ant-modal-root .ant-modal-mask {
    z-index: 9999 !important;
  }
  //TABLE

  .ant-table-wrapper{
    .ant-spin-nested-loading{
      height:100%;
        .ant-spin-container{
        height:100% !important;
        }
    }
  }
  .ant-table-tbody > tr:hover {
    background-color: ${({ theme }) => theme.colors.bgPrimary} !important;
    cursor: auto !important;
  }

  .ant-table-thead{
    tr>th{
      font-size: 14px !important;
      line-height: 21px !important;
      font-weight:700 !important;
      border-bottom: 1px solid ${({ theme }) =>
        theme.colors.grayVeryLight} !important;
      padding: 10px 12px !important;

    }
    th.ant-table-cell::before{
      background-color:${({ theme }) => theme.colors.grayVeryLight} !important;
    }
    th.ant-table-cell:hover{
      background-color:${({ theme }) => theme.colors.grayVeryLight} !important;
    }
  }
  .ant-spin-container {
    .ant-empty-description{
      color: ${({ theme }) => theme.colors.dark} !important;
    }
    .ant-empty svg g{
      ellipse{
        fill: ${({ theme }) => theme.colors.bluePrimary} !important;
      }
      path:nth-child(1){
        stroke: ${({ theme }) => theme.colors.bluePrimary} !important;
      }
      path:nth-child(2){
        fill: ${({ theme }) => theme.colors.bluePrimary} !important;
        stroke: ${({ theme }) => theme.colors.blueAccent} !important;
      }
    }
  }
  .ant-table-tbody{
    tr>td{
      font-weight:500 !important;
      padding: 17px 12px !important;
      background-color: transparent !important;
    }
  }

    .ant-table-content{
      background-color: ${({ theme }) => theme.colors.light};
      tbody{
        tr > td{
          border-bottom: 0.5px solid ${({ theme }) =>
            theme.colors.grayLightAccent} !important;
        }
      }
      tbody > tr:last-child{
          border-bottom: 0px !important;
      }
    }

    
    // Change this properly it overrides all select addon  styles
  /* .ant-select,
  .ant-input-affix-wrapper {
    background-color: ${({ theme }) => theme.colors.light};
  } */

  //INPUT

  .ant-input{
    background-color:${({ theme }) => theme.colors.light};
  }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
        outline:none;
      }





    .ant-input-group{
  .ant-input-outlined {
    height: 34px;
  }
    }


  .ant-input-group-addon {
    background-color: ${({ theme }) => theme.colors.primaryHighlight};
     /* min-width: 90px !important; */
  }

   .ant-input-number-group-addon {
    background-color: ${({ theme }) =>
      theme.colors.primaryHighlight} !important;
     min-width: 90px !important;

  }


  //Menu

  .ant-menu{
    background-color: transparent;
  }

  .ant-avatar-circle{
    span{
      line-height:20px;
    }
  }


  //DatePicker

  .ant-picker{
    min-width:150px;
    .ant-picker-suffix{
      color:${({ theme }) => theme.colors.light};
      font-size:18px;
    }
    .ant-picker-clear{
      font-size: 16px;
      right:24px;
      color: ${({ theme }) => theme.colors.grayPrimary};
      &:hover{
        .ant-picker-suffix{
           color: transparent !important;
           display:none;
        }
      color: ${({ theme }) => theme.colors.light};
      }
    }
  }



  .ant-picker-panel-container{
    border:1px solid ${({ theme }) => theme.colors.light};
  }


  //Card

  .ant-card{
    background-color: ${({ theme }) => theme.colors.light};
  }

  //Button

  
  .default-icon-button {
    &:hover span > svg {
      fill: ${({ theme }) => theme.colors.light} !important;
    }
    &:not(:hover) span > svg {
      fill: ${({ theme }) => theme.colors.bluePrimary} !important;
    }
  }


  .ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .ant-btn > span{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1.5714 !important;
  }

  .ant-btn-default:hover{
    background-color: ${({ theme }) => theme.colors.blueLightMedium} !important;
    color: ${({ theme }) => theme.colors.light} !important;
  }

  .ant-btn-icon-only:hover svg {
    fill: ${({ theme }) => theme.colors.light} !important;
  }
  //SELECT

  .ant-select-dropdown{
    border:0.5px solid ${({ theme }) => theme.colors.light};
  }

  //Recharts
  .recharts-legend-item-text {
    font-size:12px !important;
    line-height: 18px !important;
    font-weight: 500 !important;
  }
  .recharts-yAxis {
    .recharts-label{
        font-size: 12px !important;
        line-height: 18px !important;
        color: ${({ theme }) => theme.colors.grayPrimary} !important;
      }
  }
  .recharts-cartesian-axis-tick-value{
    font-size: 12px !important;
    line-height: 18px !important;
    color: ${({ theme }) => theme.colors.grayDark} !important;
  }
  .recharts-cartesian-grid-horizontal{
    line{
      stroke: ${({ theme }) => theme.colors.bgGrayLight} !important;
    }
  }
  //React Flow
  .react-flow__handle {
  opacity: 0;
  }
  .react-flow__panel {
    display: none;
  }
  .react-flow__edge-textbg{
    display: none;
  }
  //  Ant Descriptions
  .ant-descriptions-item-label{
    padding: 0px !important;
  }
  .ant-descriptions-view{
    border:none !important;
    border-radius: 0px !important;
  }


  //TAG
  .ant-tag{
    margin:0;
  }

  .warning-tag{
    border: 1px solid ${({ theme }) => theme.colors.alertWarning};
    background-color: transparent;
    color:${({ theme }) => theme.colors.alertWarning};
    min-width:62px;
    text-align:center;
    padding:5px 7px;
    line-height:13px;
  }

  .critical-tag{
    border: 1px solid ${({ theme }) => theme.colors.alertError};
    background-color: transparent;
    color:${({ theme }) => theme.colors.alertError};
    min-width:62px;
    text-align:center;
    padding:5px 7px;
    line-height:13px;
  }


  &:focus-visible{
    outline:none !important;
  }
  span.ant-select-selection-item{
    cursor: pointer;
  }

  .action-button-red{
    :hover{
      border: 1px solid ${({ theme }) => theme.colors.red};
      color: ${({ theme }) => theme.colors.red}
    }
  }
  .action-button-green{
    :hover{
      border: 1px solid ${({ theme }) => theme.colors.greenPrimary};
      color: ${({ theme }) => theme.colors.greenPrimary}
    }
  }

  .table-row-italic{
    font-style: italic;
    font-size: 12px !important;

    td {
      font-size: 12px !important;
    }
  }

  .table-row-bold{
    background-color: ${({ theme }) => theme.colors.grayPale} !important;
    font-weight: 600;
     td {
      font-weight: 600 !important;
    }
  }

  .table-row-red{
   color: ${({ theme }) => theme.colors.red};
  }
  .simulation-item {
    color: ${({ theme }) => theme.colors.primary};
    padding-right:4px;

    &:hover{
      cursor: pointer;
    }
  }
  .ant-table-expanded-row-fixed{
    width: auto !important;
  }
  .ant-table-expanded-row-fixed::after{
    inset-inline-end: 0px !important;
    border-inline-end: 0px !important;
  }

  .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 0px !important;
  }
  .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
    border-start-end-radius: 0px !important;
  }
  .ant-table-wrapper .ant-table-container table>tbody>tr:last-child >*:first-child {
    border-end-start-radius: 6px !important;
  }
  .ant-table-wrapper .ant-table-container table>tbody>tr:last-child >*:last-child {
    border-end-end-radius: 6px !important;
  }

  .ant-table-thead > tr .ant-table-cell{
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.light};
    border-right: 2px;
    border-bottom: 2px !important;
  }
  .ant-table-thead > tr .ant-table-cell:first-child{
    border-left: 0px !important;
  }
  .ant-table-thead > tr .ant-table-cell:last-child{
    border-right: 0px !important;
  }
  .ant-table-thead > tr .ant-table-cell::before {
    display: none;
  }
  .ant-table-tbody > tr > td {
    color: ${({ theme }) => theme.colors.grayPrimary};
  }

  .ant-table-thead > tr > th .ant-table-column-sorter-up,
  .ant-table-thead > tr > th .ant-table-column-sorter-down {
    color: ${({ theme }) => theme.colors.dark};
  }

  .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td{
    color: ${({ theme }) => theme.colors.dark};
  } 
  .ant-card-head-custom{
    background: ${({ theme }) => theme.colors.blueDark};
    color: ${({ theme }) => theme.colors.light} !important;
  }

  .ant-table-cell{
    background: ${({ theme }) => theme.colors.light};
  }

  .ant-table-wrapper .ant-table-cell-fix-left, .ant-table-wrapper .ant-table-cell-fix-right{
    background: ${({ theme }) => theme.colors.light};
  }
  .ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td {
    background: ${({ theme }) => theme.colors.blueLightest};
  }
  .ant-table-wrapper
  .ant-table-cell-fix-left{
    ::after{
      background: ${({ theme }) => theme.colors.light};
    }
  }

  .ant-layout-content:has(> .checkout-page-styles) {
    padding:0px;
  }

  .ant-layout-sider-collapsed{
    width:86px !important;
    max-width:86px !important;
    min-width:86px !important;
  }

  /*SCROLLBAR*/
   /* Scrollbar Stili */
  ::-webkit-scrollbar {
    width: 8px;
    
  }

  /* Scrollbar Rayı */
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.light};
  
  }

  /* Scrollbar (Thumb) */
  ::-webkit-scrollbar-thumb {
    background:  ${({ theme }) => theme.colors.grayLightAccent};
    border-radius: 4px;   
  }

  /* Scrollbar (Thumb) Hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.grayAccent};
  }

  .ant-table-wrapper .ant-table{
  scrollbar-color: ${({ theme }) => theme.colors.grayLightAccent} ${({
    theme,
  }) => theme.colors.light};
  scroll-behavior: smooth;
  }
`;
